<template>
  <b-row>
    <b-col>
      <h1>{{ $t('menu.manage-permissions') }}</h1>
    </b-col>

    <b-colxx xxs="12">
      <b-row class="text-center">
        <b-colxx>
          <b-card class="mb-4 mr-10 text-center">
            <router-link to="?">
              <img
                :src="employeePhoto"
                alt="Card image cap"
                class="
                  img-thumbnail
                  list-thumbnail
                  rounded-circle
                  border-0
                  mb-4
                "
              />
              <h6 class="mb-1 card-subtitle">{{ this.employeeDisplayName }}</h6>
              <h6 class="mb-1 card-subtitle">Employee ID: {{ employeeID }}</h6>
            </router-link>
          </b-card>
        </b-colxx>
      </b-row>
    </b-colxx>

    <b-colxx>
      <b-row>
        <b-colxx xxs="10" md="6" xl="6" xxl="6">
          <b-card class="mb-4" title="Manage Employee Roles">
            <b-button
              v-b-toggle.collapseAccordion1
              class="btn btn-primary collapsed dropdown-toggle"
              >Manage Employee Roles</b-button
            >

            <b-collapse id="collapseAccordion1" visible accordion="my-accordion">
              <div class="p-4">
                <b-form-checkbox-group
                  v-model="roleSelected"
                  :options="roleOptions"
                  disabled-field="notEnabled"
                  stacked
                ></b-form-checkbox-group>
              </div>
              <div>
                <b-button
                  variant="primary"
                  size="lg"
                  class="mb-3 ml-4"
                  @click="saveNewRole()"
                  >Save Role</b-button
                >
              </div>
            </b-collapse>
          </b-card>
        </b-colxx>

        <b-colxx xxs="10" md="6" xl="6" xxl="6">
          <b-card class="mb-4" title="Manage Employee Permissions">
            <b-button
              v-b-toggle.collapseAccordion2
              class="btn btn-primary collapsed dropdown-toggle"
              >Manage Employee Permissions</b-button
            >
            <b-collapse id="collapseAccordion2" accordion="my-accordion">
              <div class="p-4">
                <b-form-checkbox-group
                  v-model.trim="permissionSelected"
                  :options="permissionOptions"
                  disabled-field="notEnabled"
                  stacked
                ></b-form-checkbox-group>
              </div>
              <b-button
                v-b-modal.modalright
                variant="primary"
                size="lg"
                class="mb-3 ml-4"
                @click="saveNewPermission"
                >Save Permission</b-button
              >
            </b-collapse>
          </b-card>

          <b-card class="mb-4">
            <b-row>
              <b-colxx lg="6">
                <b-form-group label="Created Date">
                  <p>{{ formatDate(getPermissionCreatedInfo('created').createdDate) }}</p>
                </b-form-group>
              </b-colxx>
              <b-colxx lg="6">
                <b-form-group label="Created By">
                  <p>{{ getPermissionCreatedInfo('created').createdBy }}</p>
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
              <b-colxx lg="6">
                <b-form-group label="Edited Date">
                  <p>{{ formatDate(getPermissionCreatedInfo('modified').createdDate) }}</p>
                </b-form-group>
              </b-colxx>
              <b-colxx lg="6">
                <b-form-group label="Edited By">
                  <p>{{ getPermissionCreatedInfo('modified').createdBy }}</p>
                </b-form-group>
              </b-colxx>
            </b-row>
          </b-card>
        </b-colxx>
      </b-row>
    </b-colxx>
  </b-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import EmployeeMixin from '../../../../mixins/EmployeeMixin.vue'
import CommonMixin from '../../../../mixins/CommonMixin.vue'
import moment from 'moment/moment.js'

export default {
  components: {},
  props: {
    employeeID: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      employeeDisplayName: '',
      roleSelected: [], // Must be an array reference!
      permissionSelected: [], // Must be an array reference!
      roleOptions: [],
      permissionOptions: [],
      roleCheckBox: [],
      employeeCurrentRoles: [],
      employeeCurrentPermission: [],
      addNewPermission: [],
      removePermission: [],
      addNewRole: [],
      removeRole: [],
      profilepics: [{ image: '6.jpg' }],
      exists: null,
      employeePhoto: null
    }
  },
  mixins: [EmployeeMixin, CommonMixin],
  async created () {
    await this.refreshData()
  },
  methods: {
    ...mapActions(['getEmployeePhoto']),
    async refreshData () {
      await this.getEmployee(this.employeeID)
      if (this.employee.azureGuid) {
        // try to get user photo from graph
        await this.getEmployeePhoto(this.employee.azureGuid)
          .then((result) => {
            if (result) {
              this.employeePhoto = result
            }
          })
      }
      if (!this.employeePhoto) {
        // failed to get a picture back from graph, so set to default
        this.employeePhoto = this.getDefaultProfilePic(
          this.employee.displayName || 'Guest User',
          {
            height: '48',
            width: '48',
            font: 'normal 18px sans-serif',
            endingPosition: '30',
            startingPosition: '10',
            startingPositionForSingleInitial: '12'
          }
        )
      }

      await this.getAllRoles()
      await this.getAllPermissions()
      await this.getEmployeePermissionByID(this.employeeID)
      this.employeeDisplayName = this.employeePermissions[0].displayName
      await this.getEmployeeRole('employeeID', this.employeeID)

      // get current user roleID
      this.roleSelected = this.employeeRole.map(r => r.roleID)

      // store user roles from API
      this.employeeCurrentRoles = this.roleSelected

      // get current user permissionID
      this.permissionSelected = this.employeePermissions.map(
        p => p.permissionID
      )

      // store user permission from API
      this.employeeCurrentPermission = this.permissionSelected

      // load roles checkbox
      this.rolesCheckBoxOptions()
      // load permission checkbox
      this.permissionCheckBoxOptions()
    },
    // get role checkbox options from API
    rolesCheckBoxOptions () {
      this.roleOptions = this.roles.map(r => {
        return {
          text: r.roleName,
          value: r.roleID
        }
      })
    },
    // get permission checkbox options from API
    permissionCheckBoxOptions () {
      this.permissionOptions = this.permissions.map(p => {
        this.checkIfPermissionAlreadyExists(p.permissionID)
        return {
          text: p.permissionName,
          value: p.permissionID,
          notEnabled: this.exists
        }
      })
    },
    async saveNewRole () {
      this.setInfoMessage('')
      // remove unchecked roles
      this.removeRole = _.differenceWith(
        this.employeeCurrentRoles,
        this.roleSelected,
        _.isEqual
      )
      // add new checked roles
      this.addNewRole = _.differenceWith(
        this.roleSelected,
        this.employeeCurrentRoles,
        _.isEqual
      )
      if (this.addNewRole.length > 0) {
        // get role objects to save
        var saveRoles = _.chain(this.roles)
          .keyBy('roleID')
          .at(this.addNewRole)
          .value()
        // create role DTOs to transfer and save
        saveRoles = saveRoles.map(saveRole => {
          return {
            employeeID: this.employeeID,
            roleID: saveRole.roleID,
            roleName: saveRole.roleName,
            lastModifiedByID: localStorage.getItem('employeeID')
          }
        })
        await this.saveEmployeeRole({ saveRoles })
        if (this.statusCode === 200) {
          this.refreshData()
        }
      }
      if (this.removeRole.length > 0) {
        // get role objects to save
        var deleteRoles = _.chain(this.roles)
          .keyBy('roleID')
          .at(this.removeRole)
          .value()
        // create role DTOs to transfer and save
        deleteRoles = deleteRoles.map(deleteRole => {
          return {
            employeeID: this.employeeID,
            roleID: deleteRole.roleID,
            roleName: deleteRole.roleName,
            lastModifiedByID: localStorage.getItem('employeeID')
          }
        })
        await deleteRoles.forEach(async deleteRole => {
          await this.deleteEmployeeRole(deleteRole)
        })

        if (this.statusCode === 200) {
          this.refreshData()
        }
      }
    },
    async saveNewPermission () {
      this.setInfoMessage('')
      // remove unchecked roles
      this.removePermission = _.differenceWith(
        this.employeeCurrentPermission,
        this.permissionSelected,
        _.isEqual
      )
      // add new checked roles
      this.addNewPermission = _.differenceWith(
        this.permissionSelected,
        this.employeeCurrentPermission,
        _.isEqual
      )
      if (this.addNewPermission.length > 0) {
        // get permission objects to save
        var savePermissions = _.chain(this.permissions)
          .keyBy('permissionID')
          .at(this.addNewPermission)
          .value()
        // create permission DTOs to transfer and save
        savePermissions = savePermissions.map(savePermission => {
          return {
            employeeID: this.employeeID,
            permissionID: savePermission.permissionID,
            permissionName: savePermission.permissionName,
            lastModifiedByID: localStorage.getItem('employeeID')
          }
        })
        await this.saveEmployeePermission({ savePermissions })
        if (this.statusCode === 200) {
          this.refreshData()
        }
      }
      if (this.removePermission.length > 0) {
        // get permission objects to save
        var deletePermissions = _.chain(this.permissions)
          .keyBy('permissionID')
          .at(this.removePermission)
          .value()
        // create role DTOs to transfer and save
        deletePermissions = deletePermissions.map(deletePermission => {
          return {
            employeeID: this.employeeID,
            permissionID: deletePermission.permissionID,
            permissionName: deletePermission.permissionName,
            lastModifiedByID: localStorage.getItem('employeeID')
          }
        })

        await deletePermissions.forEach(async deletePermission => {
          await this.deleteEmployeePermission(deletePermission)
        })

        if (this.statusCode === 200) {
          this.refreshData()
        }
      }
    },

    // if the permission is set because of current role disable
    checkIfPermissionAlreadyExists (permissionID) {
      this.exists = this.employeePermissions
        .filter(r => r.permissionType === 'RoleBased')
        .map(c => c.permissionID)
        .includes(permissionID)
    },

    getPermissionCreatedInfo (action) {
      var permissionsCreatedInfo = this.employeePermissions.map(p => ({
        createdDate: p.erpCreatedDate,
        createdBy: p.erpCreatedBy
      }))
      if (action === 'created') {
        // sort created date by ascending order to get the oldest created date for an employee permission. This will be Created Date on employee screen.
        permissionsCreatedInfo.sort(function (a, b) {
          return b.createdDate < a.createdDate ? 1 : -1
        })
      } else if (action === 'modified') {
        // sort created date by descending order to get the most recent created date for an employee permission. This will be Edited Date on employee screen.
        permissionsCreatedInfo.sort(function (a, b) {
          return a.createdDate < b.createdDate ? 1 : -1
        })
      }
      return permissionsCreatedInfo[0]
    },
    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD, hh:mm A').format('MM/DD/YYYY h:mm A')
      }
    }
  }
}
</script>
<style scoped></style>
